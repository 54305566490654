// @font-face{
//     font-family: 'Source Sans Variable';
//     font-weight: 200 900;
//     font-style: normal;
//     font-stretch: normal;
//     src: url('/assets/fonts/WOFF2/VAR/SourceSansVariable-Roman.ttf.woff2') format('woff2'),
//          url('/assets/fonts/WOFF/VAR/SourceSansVariable-Roman.ttf.woff') format('woff'),
//          url('/assets/fonts/VAR/SourceSansVariable-Roman.ttf') format('truetype');
//     font-display: swap;
// }

@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Raleway');
